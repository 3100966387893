import axios from 'axios';

export default async (data) => {
  const searchParams = new URLSearchParams(window.location.search);
  const register_url = searchParams.has('referred_by')
    ? `/v1/register?referred_by=${searchParams.get('referred_by')}`
    : '/v1/register';

  return await axios.post(register_url, data).then(({ data: res }) => res.data);
};
