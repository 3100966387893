export const FORM_FIELD_TYPES = {
  input: 'input',
  password: 'password',
  select: 'select',
  selectAutocomplete: 'select-autocomplete',
  relationSelect: 'relation-select',
  autocomplete: 'autocomplete',
  relationAutocomplete: 'relation-autocomplete',
  date: 'date',
  upload: 'upload', //Устарело, взамен использовать uploadImage
  uploadImage: 'uploadImage',
  html: 'html',
  // autofilterWithAdd: 'autofilterWithAdd',
  checkBox: 'checkBox',
  textarea: 'textarea',
  color: 'color',
  innerBlock: 'inner-block',
};

export const WIDGET_PROPERTY = {
  VALUE: 'value',
  DISABLE: 'disable',
  VISIBLE: 'visible',
  REQUIRED: 'required',
  OPTIONS: 'options',
  RELATION: 'relation',
  MIN: 'min',
  MAX: 'max',
  INPUT_TYPE: 'inputType',
  PREFIX: 'prefix',
  SUFFIX: 'suffix',
  SAVE: 'save',
  LABEL: 'label',
  RULES: 'rules',
  CLEARABLE: 'clearable',
  ROW: 'row',
  PASSWORD_REPEAT: 'passwordRepeat',
  PASSWORD_REPEAT_LABEL: 'passwordRepeatLabel',
  PASSWORD_REPEAT_APPEARENCE: 'passwordRepeatAppearence',
  OUTLINED: 'outlined',
  FIELDS: 'fields',
};

export const WIDGET_EMPTY_VALUE = Symbol('WIDGET_EMPTY_VALUE');
