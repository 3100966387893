export default {
  form_edit_dialog: {
    title_create: 'Create Custom Payout',
    title_update: 'Edit Custom Payout',
    button_create: 'create',
    button_update: 'save',
    button_cancel: 'cancel',
  },
  form_labels: {
    site: 'Affiliate',
    amount: 'Amount',
  },
  data_table: {
    menu_actions: {
      edit: 'edit',
      delete: 'delete',
    },
  },
  alert: {
    created_successfully: 'Affiliate added successfully',
    updated_successfully: 'Affiliate updated successfully',
    updated_not_updated: 'Affiliate not updated',
    deleted_successfully: 'Affiliate deleted successfully',
  }
};
