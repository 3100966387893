export default {
  header: {
    title: 'Campaigns',
    description: 'View and create new traffic campaigns.',
  },
  filter_block: {
    title: 'Filter Campaigns',
    button_apply: 'apply',
    button_clear: 'Clear All Filters',
  },
  form_edit_block: {
    button_create: 'create new campaign',
    button_update: 'update campaign',
    button_clear: 'cancel update campaign',
  },
  form_edit_dialog: {
    title_create: 'create new campaign',
    title_update: 'update campaign',
    button_create: 'create new campaign',
    button_update: 'update campaign',
    button_close: 'close',
  },
  alert: {
    created_successfully: 'Campaign created successfully',
    updated_successfully: 'Campaign updated successfully',
    deleted_successfully: 'Campaign deleted successfully',
  },
  data_table: {
    title: '',
    button_create: 'create new campaign',
    copy_dialog_link_header: 'Get Link',
    error_get_link: 'Get link error!',
    menu_actions: {
      edit: 'Edit',
      delete: 'Delete',
      statistics: 'Statistics',
      get_link: 'Get Link',
    },
    delete_dialog: {
      title: 'Delete campaign?',
      button_yes: 'Yes',
      button_no: 'cancel',
    },
  },
  data_table_headers: {
    users: 'Affiliate Name',
    name: 'Campaign Name',
    date_created: 'Date Created',
    site: 'Site',
    payout_type: 'Payout Type',
    destination: 'Destination',
    targeting: 'Targeting',
    geo: 'Geo',
    all_time_earnings: 'All Time Earnings',
    actions: 'Actions',
  },
  form_labels: {
    name: 'Campaign Name',
    offer: 'Offer',
    site: 'Site',
    payout_type: 'Payout Type',
    destination_type: 'Destination Type',
    destination: 'Destination',
    geo_targeting: 'Geo Targeting',
  },
  filter_labels: {
    users: 'Affiliate Name',
    name: 'Campaign Name',
    site: 'Site',
    payout_type: 'Payout Type',
    destination_type: 'Destination Type',
    destination: 'Destination',
    geo_targeting: 'Geo Targeting',
  },
};
