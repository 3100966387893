import axios from 'axios';
import { serialize } from 'object-to-formdata';

export default async function (_id, data, params) {
  delete data.id;

  data.methods = [String(data.methods)];

  return await axios.post(`/v1/user/profile`, serialize(data), { params }).then(({ data }) => data);
}
