export const FORM_FIELD_VALIDATES = {
  NUMBER_PRECISION_2_NOT_ZERO: (value) =>
        /^-?[0-9]\d*(\.\d{0,2})?$/.test(value) && 0 !== Number(value) || `This field is invalid`,
  REQUIRED: (value) => !!value || value === 0 || 'This field is required',
  POSITIVE_NUMBER: (value) =>
    /^(\d+\.\d+|\d*)$/.test(value) || 'This field must be a positive number.',
  EMAIL: (value) =>
    /^[\da-zA-Z_-][\da-zA-Z._-]*@[\da-zA-Z_-]+(\.[\da-zA-Z_-]+)+$/.test(value) ||
    'This field must be a email.',
  SITE: (value) =>
    /^(http[s]?:\/\/|)*[\da-zA-Z_-]+(\.[\da-zA-Z]+)+$/.test(value) || `This field must be a url.`,
  PERCENTAGES: (value) =>
    (Number(value) >= 0 && Number(value) <= 100) ||
    'This field must be a positive number from 0 to 100',
};

export function checkRules(rules, value) {
  let errors = [];
  rules.forEach((rule) => {
    const result = rule(value);
    if (result !== true) {
      errors.push(result);
    }
  });
  return errors;
}
