<template>
  <footer
    class="footer"
    data-testid="elem_u_000517"
  >
    <div
      class="footer__content d-flex flex-column justify-space-between"
      data-testid="elem_u_000519"
    >
      <div
        class="d-flex justify-space-between footer-logo-container"
        data-testid="elem_u_000520"
      >
        <router-link
          class="footer__logo"
          :to="{ name: isClient ? 'Dashboard' : 'Home' }"
          data-testid="elem_u_000521"
        >
          <v-img
            height="16"
            width="71.8"
            data-testid="elem_u_000522"
            src="@/assets/img/footer-logo.png"
            alt="Footer logo"
            contain
          />
        </router-link>
        <div
          class="footer__copy"
          data-testid="elem_u_000525"
        >
          &nbsp;© All rights reserved {{ new Date().getFullYear() }}
        </div>
      </div>

      <div class="footer-buttons">
        <v-btn
          text
          @click="openTermsAndConditions"
          x-small
          class="footer-button"
          color="#FFEE9E"
          data-testid="elem_u_000523"
        >
          <v-icon
            color="#FFEE9E"
            size="18"
            data-testid="elem_u_000524"
          >
            assignment
          </v-icon>
          <span> Terms <span class="text-lowercase">of</span> Service </span>
        </v-btn>

        <v-btn
          text
          @click="openPrivacyPolicy"
          x-small
          class="footer-button"
          color="#FFEE9E"
          data-testid="elem_u_000523"
        >
          <v-icon
            color="#FFEE9E"
            size="18"
            data-testid="elem_u_000524"
          >
            admin_panel_settings
          </v-icon>
          <span> Privacy Policy </span>
        </v-btn>

        <v-btn
          v-if="!isAuthenticated || isClient"
          text
          @click="openContactUs"
          x-small
          class="footer-button"
          color="#FFEE9E"
          data-testid="elem_u_000523"
        >
          <v-icon
            color="#FFEE9E"
            size="18"
            data-testid="elem_u_000524"
          >
            mail
          </v-icon>
          <span> Contact Us </span>
        </v-btn>
      </div>
    </div>

    <ContactUsDialog
      ref="contactUsDialog"
      data-testid="elem_u_000526"
    />
  </footer>
</template>

<script>
export default {
  components: {
    ContactUsDialog: () => import('@/components/common/ContactUsDialog'),
  },

  props: {
    isContainer: {
      type: Boolean,
      default: true,
    },

    isOuterPage: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isClient() {
      return this.$store.getters['user/isClient'];
    },

    isAuthenticated() {
      return this.$store.getters['auth/authenticated'];
    },
  },

  methods: {
    openTermsAndConditions() {
      const routeData = this.$router.resolve({
        name: 'TermsAndConditions',
      });

      window.open(routeData.href, '_blank');
    },

    openPrivacyPolicy() {
      const routeData = this.$router.resolve({
        name: 'PrivacyPolicy',
      });

      window.open(routeData.href, '_blank');
    },

    openContactUs() {
      this.$refs.contactUsDialog.exec();
    },
  },
};
</script>

<style lang="scss">
.footer {
  width: 100%;
}

.footer__content {
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.16);
  align-items: center;
}

.footer__copy {
  font-size: 12px !important;
  line-height: 12px !important;
  color: var(--color-grey-600);
  text-align: right;
  letter-spacing: 0.1px;
}

.footer-logo-container {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.footer-buttons {
  align-self: start;
}

.footer-button {
  min-height: unset;
  min-width: unset;
  height: min-content !important;
  padding: 0 !important;

  &:not(:last-child) {
    margin-right: 20px;
  }

  .v-btn__content {
    display: flex;
    gap: 5px;
    align-items: center;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0px;
    color: var(--color-primary-hover);
  }
}

#home {
  .footer-button {
    display: none;
  }
}
</style>
