export default {
  header: {
    title: 'Offers',
    description:
      'Filter and view offers for selected site and generate tracking links for chosen offer.',
  },
  button_create: 'create new offer',
  alert: {
    created_successfully: 'Offer created successfully',
    updated_successfully: 'Offer updated successfully',
    deleted_successfully: 'Offer deleted successfully',
  },
  filter_block: {
    title: 'Filter Offers',
    button_apply: 'apply',
    button_clear: 'Clear All Filters',
  },
  creative_block: {
    button_get_link: 'get link',
    button_view: 'view',
    menu_actions: {
      postbacks: 'postbacks',
      pop_code: 'pop code',
      advanced: 'Advanced',
      landing_pages: 'Landing Pages',
    },
    copy_dialog_link_header: 'Get Link',
    error_get_offer_link: 'Error for get offer link!',
  },
  filter_labels: {
    site: 'Site',
    payout_type: 'Payout Type',
  },
  form_edit_block: {
    button_create: 'create new offer',
    button_update: 'update offer',
    button_clear: 'cancel update offer',
    button_upload: 'Upload Image',
  },
  form_edit_dialog: {
    title_create: 'create new offer',
    title_update: 'edit offer',
    button_create: 'create offer',
    button_update: 'update',
    button_close: 'close',
  },
  form_labels: {
    logo: 'Upload Image',
    site: 'Site',
    landing_page: 'Default Landing Page',
    payout_type: 'Payout Type',
    default_payout: 'Default Payout',
    name: 'Name',
  },
};
